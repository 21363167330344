import React from "react";
import PropTypes from "prop-types";
import EmailInputComponent from "./email-input-component";

const EnterEmailComponent = ({ handleSetStep = null }) => {
  const handleInput = (value) => {
    handleSetStep(value);
  };
  return (
    <>
      <div className="signup-context-title">Forgot your password?</div>
      <div className="forgot-password-description">
        Enter your email address and we'll send you a code to reset your
        password
      </div>
      <div className="login-body">
        <EmailInputComponent handleInput={handleInput} />
      </div>
    </>
  );
};

EnterEmailComponent.propTypes = {
  handleSetStep: PropTypes.func,
};

export default EnterEmailComponent;
