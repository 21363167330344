import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "gatsby";
import React from "react";

const BackToLoginButton = () => {
  return (
    <div className="text-left">
      <Link className="back-to-login" to="/login">
        <ArrowBackIcon className="back-icon" fontSize="small" />
        Back to Login Page
      </Link>
    </div>
  );
};

export default BackToLoginButton;
