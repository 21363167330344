import React from "react";

import PropTypes from "prop-types";
import { Button, CircularProgress } from "@mui/material";

const LOADING_SPINNER = <CircularProgress color="inherit" size={24} />;

const CustomButton = ({
  className = "",
  buttonType = "fill",
  fillDark = false,
  disabled = false,
  label = "",
  onClick = () => {},
  onKeyPress = () => {},
  isLoading = false,
  href,
  type = "button",
}) => {
  return (
    <Button
      type={type}
      disabled={disabled}
      target="_blank"
      className={`${className} custom-button-${buttonType}${
        fillDark ? "-dark" : ""
      }`}
      href={href}
      onClick={onClick}
      onKeyPress={onKeyPress}
    >
      {isLoading ? LOADING_SPINNER : label}
    </Button>
  );
};

const CustomOutlineButton = ({
  className = "",
  label = "",
  onClick = () => {},
  onKeyPress = () => {},
  href = "",
  disabled = false,
  isLoading = false,
  buttonType = "dark",
}) => {
  return (
    <Button
      disabled={disabled}
      target="_blank"
      className={`${className} custom-button-${buttonType}`}
      href={href}
      onClick={onClick}
      onKeyPress={onKeyPress}
    >
      {isLoading ? LOADING_SPINNER : label}
    </Button>
  );
};

const CustomButtonWithIcon = ({
  label = "",
  onClick = () => {},
  onKeyPress = () => {},
  href = "",
  disabled = false,
  isLoading = false,
  buttonType = "dark",
  icon,
}) => {
  return (
    <Button
      disabled={disabled}
      target="_blank"
      className={`custom-button-${buttonType}`}
      href={href}
      onClick={onClick}
      onKeyPress={onKeyPress}
    >
      {isLoading ? LOADING_SPINNER : label}
      <div className="custom-button__icon">{icon}</div>
    </Button>
  );
};

CustomButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  onKeyPress: PropTypes.func,
};

CustomOutlineButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  onKeyPress: PropTypes.func,
};

CustomButtonWithIcon.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  onKeyPress: PropTypes.func,
};
export { CustomOutlineButton, CustomButtonWithIcon };
export default CustomButton;
